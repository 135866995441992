<template>
  <div class="container-fluid px-4">
    <router-view/>
  </div>
</template>

<script>
const { mapActions } = require('vuex')
export default {
  name: "CompanyInstruction"
}
</script>

<style scoped lang="scss">

</style>
